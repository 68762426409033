<template>
    <div ref="tab"
        :tabindex="props.tabIndex"
        class="row-container o365-caching-tab"
        :id="tabControl.id"
        :data-tabs-id="tabsControl.tabsId"
        role="tabpanel" 
        :class="[{active: _active, show: _active, 'tab-pane fade': !compactView}, paneClass]"
        :style="[paneStyle]">
        
        <ErrorRenderer v-if="capturedError" />
        
        <template v-else-if="compactView">
            <template v-if="compactViewMode === 'cards'">
                <article class="border mb-1 shadow shadow-sm bg-white">
                    <div class="d-flex" :style="{ 'max-height': `${props.tabHeight ?? 500}px` }">
                        <slot :compactView="true"></slot>
                    </div>
                </article>
            </template>
            <template v-else>
                <div class="accordion-item my-1" :id="`acc-${tabControl.id}`">
                    <h6 class="accordion-header" :id="accordionId">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#${collapseId}`" aria-expanded="true" :aria-controls="collapseId" style="font-weight: 600;">
                            {{ props.title }}
                        </button>
                    </h6>
                    <div :id="collapseId" class="accordion-collapse collapse show" :data-bs-parent="`#acc-${tabControl.id}`">
                        <div class="d-flex" :style="{ 'max-height': `${props.tabHeight ?? 500}px` }">
                            <slot :compactView="true"></slot>
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-else-if="alwaysRender">
            <slot :compactView="false"></slot>
        </template>

        <template v-else-if="disableKeepAlive">
            <slot v-if="tabControl.rendered" :compactView="false"></slot> 
        </template>

        <KeepAlive v-else>
            <CachedSlot v-if="tabControl.rendered">
                <slot :compactView="false"></slot>
            </CachedSlot>
        </KeepAlive>
    </div>
</template>

<script setup lang="ts">
import { InjectionKeys, logger } from 'o365-utils';
import { useErrorCapture } from 'o365-vue-utils';
import { Tab } from './Tabs.TabControl.ts'
import { ref, inject, reactive, onBeforeUnmount, defineComponent, watch } from 'vue';

const props = defineProps<{
    id?: string,
    tabIndex?: string | number,
    active?: boolean,
    title?: string,
    alwaysRender?: boolean,
    disableKeepAlive?: boolean,
    paneClass?: any,
    paneStyle?: any,
    itemClass?: any,
    itemStyle?: any,
    linkClass?: any,
    linkStyle?: any,
    disabled?: boolean,
    tabHeight?: number | number
}>();

const uuid = window.crypto.randomUUID();

const accordionId = `accItem-${uuid}`;
const collapseId = `col-${uuid}`;

const slots = defineSlots<{
      default(props: { compactView: boolean }): any,
      title(): any
}>();

const CachedSlot = defineComponent({
    name: 'CachedTabSlot',
    setup(_props, context) {
        return () => context.slots.default();
    }
});
let _active = props.active;

const tab = ref(null);
const tabsControl = inject(InjectionKeys.tabControlKey, null);
if (!tabsControl) { logger.error('<Tab> component must be inside <Tabs> component') } 

tabsControl.value?.on("TabChanged", function({ tabId }){
    _active = tabControl?.id === tabId;
})

const compactView = ref(tabsControl.value.compactView);
const compactViewMode = ref(tabsControl.value.compactViewMode);

watch(tabsControl, newVal => {
    compactView.value = newVal.compactView;
}, { deep: true })

const tabControl = reactive(new Tab({
    props: props,
    elRef: tab,
    titleSlot: slots.title,
}));

tabsControl.value.addTab(tabControl, (value: boolean) => { _active = value; });

onBeforeUnmount(() => {
    tabsControl.value.removeTab(tabControl);
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render tab content: ${props.id}`,
    errorRenderFunctionOptions: {
        type: 'card',
        uiMessage: 'An unhandled error has occurred when rendering the contents of this tab'
    }
});
</script>